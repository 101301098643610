import classnames from 'classnames';
import styles from './Button.module.css';

const Button = ({ className, variant = 'primary', loading = false, ...props }) => {
  const classes = classnames(styles.Button, className, styles[variant]);

  return (
    <button disabled={loading} className={classes} type={props.type ?? 'button'} {...props}>
      {props.children}
    </button>
  );
};

export { Button };
