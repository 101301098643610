import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from '@app/components/ui/Button';
import { pushData } from '@app/utils/gtm';
import users from '@app/api/users';
import styles from './LoginButton.module.css';

const LoginButton = ({ className }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const isAuthenticated = authState && authState.isAuthenticated;

  useEffect(() => {
    const reportLocation = async () => {
      const user = await users.me();
      if (user.data.location) {
        pushData({
          currentLocation: user.data.location.location,
          currentLocationCode: user.data.location.code,
        });
      } else {
        pushData({
          currentLocation: 'N/A',
          currentLocationCode: 'N/A',
        });
      }
    };

    if (isAuthenticated) {
      reportLocation();
    }
  }, [isAuthenticated]);

  const login = async () => {
    setLoading(true);
    await oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  const logout = async () => {
    setLoading(true);
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}`,
    });
  };

  const classes = classnames(styles.LoginButton, className);

  // Hide the button when signed in
  if (isAuthenticated) {
    return (
      <Button disabled={loading} onClick={logout} className={classes} data-ga="sign-out-button">
        Sign Out
      </Button>
    );
  }

  return (
    <Button disabled={loading} onClick={login} className={classes} data-ga="sign-in-button">
      Sign In
    </Button>
  );
};

export default LoginButton;
