import { useRef, useEffect } from 'react';
import styles from './index.module.css';

function LazyVideo(props) {
  const {
    src,
    type,
    children,
    playing = false,
    muted = true,
    loop = true,
    playsInline = true,
    ...rest
  } = props;

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (playing) {
      videoElement.play().catch((error) => {
        console.error('Video failed to play', error);
      });
    } else {
      videoElement.pause();
    }

    return () => {
      videoElement.pause();
    };
  }, [playing, videoRef]);

  return (
    <video
      ref={videoRef}
      className={styles.Video}
      muted={muted}
      loop={loop}
      preload="auto"
      playsInline={playsInline}
      {...rest}
    >
      {!children && type && <source src={src} type={type} />}
    </video>
  );
}

export default LazyVideo;
