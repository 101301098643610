const LocationCode = ({ code, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 94 94"
    {...rest}
  >
    <g transform="translate(3 3)" fill="none" fillRule="evenodd">
      <circle stroke="#fff" strokeWidth={5} cx={44} cy={44} r={44} />
      <ellipse stroke="#FBB649" strokeWidth={2} cx={44} cy={22} rx={14} ry={3} />

      <text
        fontFamily="Brandon Text"
        fontSize={27}
        fontWeight="900"
        fill="#fff"
        textAnchor="middle"
        x={44}
        y={54}
      >
        {code}
      </text>
    </g>
  </svg>
);

export default LocationCode;
