import noop from 'lodash/noop';

export const log = import.meta.env.NODE_ENV === 'development' ? console.log : noop;
export const openGroup = import.meta.env.NODE_ENV === 'development' ? console.groupCollapsed : noop;
export const closeGroup = import.meta.env.NODE_ENV === 'development' ? console.groupEnd : noop;

export default {
  log,
  openGroup,
  closeGroup,
};
