import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { setTokenProvider } from '@app/api/token';
import LoginInit from '@app/components/LoginInit';
import LoginCallback from '@app/components/LoginCallback';
import Screen from '@app/pages/Screen';
import Redirect from '@app/pages/Redirect';
import config from './config';

const oktaAuth = new OktaAuth(config.oidc);

setTokenProvider(() => {
  return oktaAuth.getAccessToken();
});

const isKioskEnabled = () => {
  return window.location.host.startsWith('kiosk');
};

const isAdesaEnabled = () => {
  return window.location.host.startsWith('adesa');
};

function App() {
  const [kiosk, setKiosk] = useState(false);
  const [adesa, setAdesa] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setKiosk(isKioskEnabled());
    setAdesa(isAdesaEnabled());
  }, [location.pathname]);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {kiosk && (
        <Routes>
          <Route path="/" element={<Screen kiosk randomize={searchParams.has('randomize')} />} />
        </Routes>
      )}

      {adesa && (
        <Routes>
          <Route
            path="/"
            element={<Screen kiosk adesa randomize={searchParams.has('randomize')} />}
          />
        </Routes>
      )}

      {!kiosk && !adesa && (
        <Routes>
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/login/init" element={<LoginInit />} />
          <Route
            exact
            path="/kiosk"
            element={<Screen kiosk randomize={searchParams.has('randomize')} />}
          />
          <Route
            exact
            path="/adesa"
            element={<Screen kiosk adesa randomize={searchParams.has('randomize')} />}
          />
          <Route path="/redirect" element={<Redirect />} />
          <Route path="/" element={<Screen randomize={searchParams.has('randomize')} />} />
        </Routes>
      )}
    </Security>
  );
}

export default App;
