import { Fragment, useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';
import { CommandType } from '@app/api/commands';
import useHub from '@app/hooks/useHub';
import users from '@app/api/users';
import Fade from '@app/components/Fade';
import { requestPermission, getPermissionStatus, initialize } from './utils';

import NotifOnIcon from './NotifOn.svg?react';
import NotifOffIcon from './NotifOff.svg?react';

import styles from './Notifier.module.css';

const Notifier = ({ className }) => {
  const { authState } = useOktaAuth();
  const [user, setUser] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [banner, setBanner] = useState(null);
  const isAuthenticated = authState && authState.isAuthenticated;

  const handleCommand = useCallback((command) => {
    if (command.type === CommandType.notifications.create) {
      const notification = command.payload;
      setBanner({ ...notification });
      setTimeout(() => {
        setBanner(null);
      }, 7000);
    }
  }, []);

  useHub(handleCommand);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await users.me();
      setUser(user.data);
    };

    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const initAdapter = async () => {
      await initialize();
      setPermissionStatus(getPermissionStatus());
    };

    if (isAuthenticated) {
      initAdapter();
    }
  }, [user, isAuthenticated]);

  const handleClick = () => {
    const permStatus = getPermissionStatus();
    setPermissionStatus(permStatus);

    // Apple Push Notifications require some kind of auth token to identify the user
    requestPermission(
      {
        id: user.id,
      },
      (updatedStatus) => {
        setPermissionStatus(updatedStatus);
      }
    );
  };

  if (isAuthenticated) {
    return (
      <Fragment>
        <button
          className={classnames(styles.NotifierButton, className)}
          onClick={() => handleClick()}
        >
          {permissionStatus === 'granted' ? (
            <NotifOnIcon className={classnames(styles.NotificationIcon, styles.NotifOnIcon)} />
          ) : (
            <NotifOffIcon className={classnames(styles.NotificationIcon, styles.NotifOffIcon)} />
          )}
        </button>

        {permissionStatus === 'default' && (
          <div className={styles.NotificationOverlay}>
            <div className={styles.OverlayContent}>
              <h2 className={styles.OverlayHeading}>Hello friend!</h2>
              <p>
                We’ve updated the Collective to allow for only the very most important
                notifications.
              </p>

              <p>
                Please click <strong>ALLOW</strong> on the notifications pop up to ensure you
                receive alerts.
              </p>
            </div>
            <div className={styles.OverlayArrow}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="174" fill="none">
                <path
                  fill="var(--ui-primary-blue)"
                  d="M13.06.94a1.5 1.5 0 0 0-2.12 0l-9.547 9.545a1.5 1.5 0 1 0 2.122 2.122L12 4.12l8.485 8.486a1.5 1.5 0 1 0 2.122-2.122L13.06.94ZM13.5 174V2h-3v172h3Z"
                />
              </svg>
            </div>
          </div>
        )}

        <Fade in={!!banner}>
          <div className={styles.Banner}>
            {banner && (
              <>
                <div>{banner.title}</div>
                {banner.body}
              </>
            )}
          </div>
        </Fade>
      </Fragment>
    );
  }

  return null;
};

export default Notifier;
