import httpClient from './client/http';
import { getAccessToken } from './token';
import { createPostFromJson } from './mappers';

export async function listAll({ endingBefore, limit = 50, adesa } = {}) {
  const headers = { 'Content-Type': 'application/json' };

  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const params = new URLSearchParams();
  params.set('limit', limit);

  if (endingBefore) {
    params.append('previous', endingBefore);
  }

  if (adesa) {
    params.append('filter[cluster]', 'Adesa');
  }

  const response = await httpClient.get(
    `${import.meta.env.VITE_REST_API_URL}/v1/screen/posts?${params.toString()}`,
    {
      headers,
    }
  );

  return {
    data: response.results.map(createPostFromJson),
    nextCursor: {
      type: 'next',
      value: response.next,
    },
    previousCursor: {
      type: 'previous',
      value: response.previous,
    },
  };
}

export async function like(post) {
  if (post.userReaction) {
    return;
  }

  const accessToken = getAccessToken();
  const response = await httpClient.post(
    `${import.meta.env.VITE_REST_API_URL}/v1/screen/reactions`,
    {
      body: JSON.stringify({
        post: post.id,
        emoji: 'heart',
      }),
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    }
  );

  return {
    data: response,
  };
}

export async function unlike(post) {
  if (!post.userReaction) {
    return;
  }

  const accessToken = getAccessToken();
  const response = await httpClient.delete(
    `${import.meta.env.VITE_REST_API_URL}/v1/screen/reactions/${post.userReaction}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
    }
  );

  return {
    data: response,
  };
}

export default {
  listAll,
  like,
  unlike,
};
