import { useEffect } from 'react';
import commands from '@app/api/commands';

export default function useHub(commandHandler) {
  // The order of below hooks is significant.
  // Subscription always goes first before connection.
  // React guarantees in-order-of-appearance execution.

  useEffect(() => {
    const unsubscribe = commands.onReceive(commandHandler);
    return () => unsubscribe();
  }, [commandHandler]);

  useEffect(() => {
    commands.connect();
    return () => commands.disconnect();
  }, []);

  return commands;
}
