const CLIENT_ID = import.meta.env.VITE_OKTA_CLIENT_ID || '{clientId}';
const ISSUER = import.meta.env.VITE_OKTA_ISSUER || 'https://{yourOktaDomain}.com';
const OKTA_TESTING_DISABLEHTTPSCHECK =
  Boolean(import.meta.env.VITE_OKTA_TESTING_DISABLEHTTPSCHECK) || false;
const BASENAME = import.meta.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'groups'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};

export default config;
