import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Select.module.css';

export const Select = forwardRef((props, ref) => {
  const {
    error,
    options = [],
    defaultOption,
    labelText,
    helpText,
    helpIcon,
    className,
    errorNoPadding,
    disabled,
    ...rest
  } = props;
  const inputClasses = classNames(
    styles.Input,
    styles.Select,
    !labelText && styles.SelectWithoutLabel
  );
  const errorClasses = classNames(styles.ErrorText, errorNoPadding && styles.errorTextNoPadding);
  return (
    <label className={classNames(className, styles.Label, disabled && styles.disabled)}>
      {labelText}
      <div className={styles.SelectWrapper}>
        <select
          ref={ref}
          className={inputClasses}
          defaultValue={defaultOption?.value}
          disabled={disabled}
          {...rest}
        >
          {defaultOption && (
            <option value="" disabled>
              {defaultOption.optionText}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.optionText}
            </option>
          ))}
        </select>
        <span className={styles.Focus}></span>
      </div>
      {helpText && !error && (
        <div className={styles.HelpText}>
          {helpIcon} {helpText}
        </div>
      )}
      {error && <div className={errorClasses}>{error.message}</div>}
    </label>
  );
});
