export const GTM_ID = import.meta.env.VITE_GTM_TRACKING_ID;

// Initialize
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

export const trackEvent = (name, options = {}) => {
  if (!GTM_ID) {
    console.warn('Google Tag Manager has not been initialized');
  }

  window.dataLayer.push({
    event: name,
    ...options,
  });
};

export const pushData = (options = {}) => {
  if (!GTM_ID) {
    console.warn('Google Tag Manager has not been initialized');
  }

  window.dataLayer.push({
    ...options,
  });
};
