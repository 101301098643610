import { io } from 'socket.io-client';

const wsUrl = import.meta.env.VITE_WS_API_URL || '';
const socket = io(wsUrl, {
  path: '/hub',
  autoConnect: false,
  transports: ['websocket'],
  secure: wsUrl.startsWith('wss:') || wsUrl.startsWith('https:') ? true : false,
});

export default socket;
