import { useRef, useEffect } from 'react';
import usePointerLock from '@app/hooks/usePointerLock';
import useFullScreen from '@app/hooks/useFullScreen';
import PostManager from './PostManager';

export const usePostManager = () => {
  const postManagerRef = useRef(null);
  if (!postManagerRef.current) {
    postManagerRef.current = new PostManager();
  }
  return postManagerRef.current;
};

export const useFullScreenMode = (enabled) => {
  const [isFullscreen, enterFullscreen, exitFullscreen] = useFullScreen();
  const [_isPointerLocked, lockPointer, unlockPointer] = usePointerLock();

  // Fullscreen mode handler
  useEffect(() => {
    const handleDoubleClick = async function () {
      if (!enabled) {
        return;
      }
      if (isFullscreen) {
        await unlockPointer();
        await exitFullscreen();
      } else {
        await enterFullscreen();
        await lockPointer();
      }
    };

    document.addEventListener('dblclick', handleDoubleClick, false);
    return () => document.removeEventListener('dblclick', handleDoubleClick, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
