import parseIsoDate from '@app/utils/datetime/parseIsoDate';
import parseIsoDurationToMinutes from '@app/utils/datetime/parseIsoDurationToMinutes';
import parseIsoDuration from '@app/utils/datetime/parseIsoDuration';

function createMediaFromJson(mediaJson) {
  return {
    keyName: mediaJson.key_name,
    publicId: mediaJson.public_id,
    resourceType: mediaJson.resource_type,
    thumbnailUrl: mediaJson.thumbnail_url,
    width: mediaJson.width,
    height: mediaJson.height,
    extension: mediaJson.extension,
  };
}

function createAuthorFromJson(postJson) {
  return {
    firstName: postJson.firstName,
    lastName: postJson.lastName,
    email: postJson.email,
  };
}

function createLocationFromCode(code) {
  return {
    code,
  };
}

function resolveDefaultTakeoverMode(postJson) {
  const isVideo = postJson.media && postJson.media.resource_type === 'video';
  return isVideo ? 'auto' : 'disabled';
}

export function createPostFromJson(postJson) {
  const defaultTakeoverMode = resolveDefaultTakeoverMode(postJson);

  return {
    id: postJson.id,
    userReaction: postJson.userReaction,
    caption: postJson.subject,
    isSuperPost: false,
    takeoverMode: postJson.takeoverMode ? postJson.takeoverMode : defaultTakeoverMode,
    takeoverDuration: postJson.takeoverDuration
      ? parseIsoDuration(postJson.takeoverDuration)
      : null,
    location: createLocationFromCode(postJson.locationCode),
    author: createAuthorFromJson(postJson),
    media: createMediaFromJson(postJson.media),
    publishedAt: parseIsoDate(postJson.publishedAt),
  };
}

export function createScheduleFromJson(scheduleJson) {
  return {
    startAt: parseIsoDate(scheduleJson.superPostLiveAt),
    endAt: parseIsoDate(scheduleJson.superPostEndAt),
    frequency: parseIsoDurationToMinutes(scheduleJson.superPostFrequency),
  };
}

export function createSuperPostFromJson(postJson) {
  const post = createPostFromJson(postJson);

  return {
    ...post,
    isSuperPost: true,
    // TODO: This should be eventually removed.
    // Old posts have not been migrated yet. Hence we try to resolve the defaults here.
    // We check the original takeoverMode, if empty, we use the auto for all superposts,
    // otherwise we fall back to the takeoverMode resolved for the plain post.
    takeoverMode: postJson.takeoverMode ? post.takeoverMode : 'auto',
    schedule: createScheduleFromJson(postJson.superpost),
  };
}

export function createDeviceFromJson(deviceJson) {
  return deviceJson;
}

export function createUserFromJson(userJson) {
  return {
    id: userJson.id,
    firstName: userJson.firstName,
    lastName: userJson.lastName,
    email: userJson.email,
    role: userJson.role,
    location: userJson.location || null,
  };
}

export function createLocationFromJson(locationJson) {
  return {
    id: locationJson.id,
    location: locationJson.location,
    code: locationJson.code,
  };
}

export default {
  createPostFromJson,
  createSuperPostFromJson,
};
