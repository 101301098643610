import { useState, useEffect, useMemo } from 'react';
import Condition from '@app/components/Condition';
import styles from './index.module.css';

const toOrientation = (event) => (event.matches ? 'portrait' : 'landscape');

const OrientationNotice = ({ suppress }) => {
  const landscapeQuery = useMemo(
    () => window.matchMedia('(orientation: portrait) and (max-aspect-ratio: 13/9)'),
    []
  );
  const [orientation, setOrientation] = useState(() => toOrientation(landscapeQuery));

  useEffect(() => {
    const handleLandscapeChange = (event) => setOrientation(toOrientation(event));
    // If initial orientation was landscape, we need to reload the page upon rotation
    // to reinitialize swiper state since it relies on correct window proportions
    if (orientation === 'landscape') {
      landscapeQuery.addListener((event) => {
        if (event.matches) {
          window.location.reload();
        }
      });
    } else {
      landscapeQuery.addListener(handleLandscapeChange);
    }

    return () => landscapeQuery.removeListener(handleLandscapeChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Condition when={!(orientation === 'portrait' || suppress)}>
      <div className={styles.Root}>
        <svg
          width="103"
          height="28"
          viewBox="0 0 103 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M51.53 24.2c26.26 0 47.54-4.53 47.54-10.1C99.07 8.52 77.79 4 51.53 4 25.28 4 4 8.52 4 14.1c0 5.57 21.28 10.1 47.53 10.1z"
            stroke="#FBB649"
            strokeWidth="7.41"
            strokeMiterlimit="3.23"
          />
        </svg>
        <p>Please rotate your device</p>
        <svg
          width="237"
          height="177"
          viewBox="0 0 237 177"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M128.19 142.9c0-5.5-4.86-10.36-10.37-10.36a10.44 10.44 0 00-10.36 10.37c0 5.83 4.53 10.36 10.36 10.36 5.5 0 10.37-4.53 10.37-10.36zm41.46-119.19c0-8.42-7.13-15.54-15.55-15.54H81.55A15.55 15.55 0 0066 23.7v134.74C66 167.2 72.8 174 81.55 174h72.55c8.42 0 15.55-6.8 15.55-15.55V23.71zm-10.37 0v134.74c0 2.92-2.59 5.19-5.18 5.19H81.55a5.12 5.12 0 01-5.19-5.19V23.71c0-2.59 2.27-5.18 5.19-5.18h72.55c2.59 0 5.18 2.6 5.18 5.18z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.24 173.55l.3-2.08c.2-1.24-.69-2.43-2.1-2.85a44.62 44.62 0 01-35.28-50.34 45.47 45.47 0 0112.43-25.25l-4.23-5.7a52.46 52.46 0 00-15.25 29.9c-4.06 27.34 14.12 53.12 41.07 58.4 1.42.42 2.84-.63 3.06-2.08z"
            fill="#fff"
          />
          <path
            d="M39.2 78.28c1.23.19 2.1 1.36 1.92 2.6l-2.83 20.17c-.28 1.98-2.81 2.6-4.02.96l-17.3-23.33c-1.2-1.63.12-3.88 2.1-3.57l20.12 3.17z"
            fill="#fff"
          />
        </svg>
      </div>
    </Condition>
  );
};

export default OrientationNotice;
