// Various HTTP status definitions:
// https://httpstatuses.com/

export const HttpStatus = {
  // 2xx Success
  SUCCESS: 200, // general success retrieving resource
  CREATED: 201, // successful POST operation
  NO_CONTENT: 204, // successful DELETE operation

  // 4xx Client Error
  BAD_REQUEST: 400, // reserved for validation errors
  UNAUTHORIZED: 401, // user is not authenticated
  FORBIDDEN: 403, // user is authenticated but didn't have the required role
  NOT_FOUND: 404, // resource does not exist
};

class HttpError {
  constructor(response) {
    this.name = 'HttpError';
    this.message = response.statusText;
    this.status = response.status;
    this.response = response;
  }
}

// Wrapper over regular fetch method that resolves
// some of its nuances, mainly that fetch does not
// treat 404 as an error.

let refreshTimer = null;

async function properFetch(url, options) {
  const response = await fetch(url, options);

  // Reload the window upon token expiration
  if (response.status === 401) {
    refreshTimer = setTimeout(() => {
      console.warn('Screen will reload in 5s to renew the token');
      window.location.reload();
    }, 5000);
  } else if (refreshTimer) {
    clearTimeout(refreshTimer);
    refreshTimer = null;
  }

  if (!response.ok) {
    throw new HttpError(response);
  }

  const text = await response.text();

  return text ? JSON.parse(text) : null;
}

export default {
  get: (url, options) => properFetch(url, options),
  patch: (url, options) => properFetch(url, { ...options, method: 'PATCH' }),
  post: (url, options) => properFetch(url, { ...options, method: 'POST' }),
  put: (url, options) => properFetch(url, { ...options, method: 'PUT' }),
  delete: (url, options) => properFetch(url, { ...options, method: 'DELETE' }),
};
