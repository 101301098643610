import { Fragment, useState, useCallback, useRef } from 'react';
import { Uppy } from '@uppy/core';
import { Dashboard, useUppy } from '@uppy/react';
import Webcam from '@uppy/webcam';
import CloudinaryPlugin from '@app/lib/uppy-cloudinary';
// import ImageCompressor from 'uppy-plugin-image-compressor';
import classnames from 'classnames';
import { CaptionEditorPanel } from './components';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Eyebrow } from '@app/components/ui';
import httpClient from '@app/api/client/http';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import './uppy.css';
import styles from './UploadContainer.module.css';

const { VITE_CLOUDINARY_API_UPLOAD_PRESET, VITE_REST_API_URL } = import.meta.env;

const UploadContainer = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const [open, setOpen] = useState(false);
  const [activePanel, setActivePanel] = useState(0);
  const [uploadedFile, setUploadedFile] = useState({});
  const [post, setPost] = useState(null);
  const initialPostId = useRef(null);
  const [inputFocused, setInputFocused] = useState(false);

  const uppy = useUppy(() => {
    const uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploadBatches: false,
      debug: false,
      thumbnailWidth: 1000,
      restrictions: {
        maxFileSize: 20971520,
        minFileSize: null,
        maxTotalFileSize: 20971520,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [
          // Video mime types
          'video/mp4',
          'video/webm',
          'video/quicktime',
          'video/x-matroska',
          'video/x-ms-wmv',
          'video/3gpp',
          // Video extensions
          '.mp4',
          '.webm',
          '.mov',
          '.mkv',
          '.wmv',
          '.3gp',
          // Image mime types
          'image/jpeg',
          'image/png',
          'image/webp',
          'image/gif',
          // Image extensions
          '.jpeg',
          '.jpg',
          '.png',
          '.webp',
          '.gif',
        ],
        requiredMetaFields: [],
      },
    })
      .use(Webcam, {
        videoConstraints: {
          facingMode: 'environment',
        },
        mirror: false,
        showVideoSourceDropdown: true,
      })
      // Temporarily disabling ImageCompressor as it breaks .gifs
      // .use(ImageCompressor, {
      //   // Options from Compressor.js https://github.com/fengyuanchen/compressorjs#options, just don’t set `success` or `error`
      //   quality: 0.9,
      //   maxWidth: 2048, // 2k
      //   maxHeight: 1080, // 2k
      // })
      .use(CloudinaryPlugin, {
        uploadPreset: VITE_CLOUDINARY_API_UPLOAD_PRESET,
        createUploadRequest: async function createUploadRequest(file, options) {
          const accessToken = oktaAuth.getAccessToken();
          const response = await httpClient.post(`${VITE_REST_API_URL}/v1/screen/posts`, {
            body: JSON.stringify({}),
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          initialPostId.current = response.id;
          setPost(response);
          return response.upload;
        },
      });

    // See all events emmitted by Uppy
    // https://uppy.io/docs/uppy/#Events

    uppy.on('file-added', (file) => {
      setActivePanel(1);
    });

    uppy.on('file-removed', (file, reason) => {
      setActivePanel(0);
    });

    uppy.on('complete', (result) => {
      if (result.successful.length > 0) {
        console.log('Upload complete! We’ve uploaded these files:', result.successful);
        setUploadedFile(result.successful[0].response);
        setActivePanel(2);
      } else {
        console.log('Upload failed', result);
      }
    });

    return uppy;
  });

  const reset = () => {
    uppy.cancelAll();
    setPost(null);
    setUploadedFile({});
    initialPostId.current = null;
    setActivePanel(0);
  };

  const togglePanel = () => {
    if (open) {
      setOpen(false);
      reset();
    } else {
      setOpen(true);
    }
  };

  const showWidget = activePanel === 0 || activePanel === 1;

  const updatePost = useCallback(
    async (data) => {
      const accessToken = oktaAuth.getAccessToken();
      await httpClient.put(
        `${import.meta.env.VITE_REST_API_URL}/v1/screen/posts/${initialPostId.current}`,
        {
          body: JSON.stringify({
            subject: data.caption,
            location: data.location,
            asset: uploadedFile,
          }),
          headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
        }
      );

      setActivePanel(3);
    },
    [initialPostId, oktaAuth, uploadedFile]
  );

  // Hide the button when signed in
  if (!authState || !authState.isAuthenticated) {
    return null;
  }

  return (
    <Fragment>
      <div
        className={classnames(styles.Scrim, open && styles.ScrimVisible)}
        onClick={togglePanel}
      ></div>

      <div className={classnames(styles.UploadContainer, open && styles.UploadContainerOpen)}>
        <div className={styles.UploadButtonContainer}>
          <button
            className={styles.UploadButton}
            type="button"
            onClick={() => togglePanel()}
            aria-label="Toggle the upload panel"
            data-ga="upload-toggle-button"
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.UploadButtonIcon}
            >
              <path
                d="M26 13C26 12.4375 25.5625 12 25 12H14V1C14 0.4375 13.5625 0 13 0C12.4375 0 12 0.4375 12 1V12H1C0.4375 12 0 12.4375 0 13C0 13.5625 0.4375 14 1 14H12V25C12 25.5625 12.4375 26 13 26C13.5625 26 14 25.5625 14 25V14H25C25.5625 14 26 13.5625 26 13Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div className={styles.UploadPanel}>
          {activePanel === 0 && (
            <div className={styles.UploadPanelText}>
              <h2>Upload to the Collective!</h2>
              <p>
                You can now upload photos and video files from where ever you access the Collective.
                Files must be a jpg, png, webp, gif, mov, mp4, wmv, 3gp, mkv, or webm and under 20mb
                in size.
              </p>
              <p>Video files over 20mb can be trimmed to a fixed 15s duration.</p>
            </div>
          )}
          {activePanel === 1 && (
            <div className={styles.UploadPanelHeading}>
              <Eyebrow element="h2">Upload Queue</Eyebrow>
            </div>
          )}

          {open && showWidget && (
            <Dashboard
              uppy={uppy}
              plugins={['Webcam', 'ScreenCapture']}
              showProgressDetails={true}
              proudlyDisplayPoweredByUppy={false}
              thumbnailWidth={1000}
            />
          )}

          {activePanel === 2 && (
            <Fragment>
              <div className={styles.UploadPanelHeading}>
                <Eyebrow element="h2">Complete Upload</Eyebrow>
              </div>
              <CaptionEditorPanel
                post={post}
                submitAction={(caption) => updatePost(caption)}
                file={uploadedFile}
                onInputFocused={(c) => setInputFocused(c)}
              />
              <div className={classnames(styles.ActionButtons, inputFocused && styles.NotSticky)}>
                <Button
                  className={styles.Button}
                  variant="secondary"
                  onClick={() => togglePanel()}
                  aria-label="Cancel upload"
                >
                  Cancel
                </Button>
                <Button
                  className={styles.Button}
                  variant="primary"
                  type="submit"
                  aria-label="Submit upload"
                  form="caption-form"
                  data-ga="upload-complete-button"
                >
                  Submit
                </Button>
              </div>
            </Fragment>
          )}

          {activePanel === 3 && (
            <div className={styles.UploadPanelText}>
              <h2>Thank you for uploading to the Collective!</h2>
              <p>
                Your file has successfully been added to the queue for approval and display on the
                Collective.
              </p>
              <p>
                Thank you for continuing to share and showcase our values across all of
                #CarvanaCountry. We love to see what you are up to, so thanks for making Carvana a
                fun place to work!
              </p>
              <Button onClick={() => reset()} variant="link">
                Upload another file
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UploadContainer;
