import Condition from '@app/components/Condition';
import styles from './index.module.css';

const OfflineNotice = ({ settings }) => {
  return (
    <Condition when={!settings.active}>
      <div className={styles.Root}>
        {settings.assetUrl ? (
          <img className={styles.Inner} src={settings.assetUrl} alt="Screen is offline" />
        ) : (
          <h1 className={styles.Inner}>Screen is offline</h1>
        )}
      </div>
    </Condition>
  );
};

export default OfflineNotice;
