import httpClient from './client/http';
import { getAccessToken } from './token';
import { createUserFromJson } from './mappers';

export async function me() {
  const accessToken = getAccessToken();
  const response = await httpClient.get(`${import.meta.env.VITE_REST_API_URL}/v1/users/me`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return {
    data: createUserFromJson(response),
  };
}

export default {
  me,
};
