import { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function Redirect() {
  const [params] = useSearchParams();
  const to = params.get('to');

  useEffect(() => {
    setTimeout(() => {
      window.location.href = to;
    }, 4000);
  }, [to]);

  return (
    <div style={{ color: 'black', margin: '20px' }}>
      You'll be redirected to <a href={to}>{to}</a> shortly.
    </div>
  );
}

export default Redirect;
