import { useState, useRef, useEffect, useLayoutEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import takeWhile from 'lodash/takeWhile';
import find from 'lodash/find';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Condition from '@app/components/Condition';

import { CommandType } from '@app/api/commands';
import useHub from '@app/hooks/useHub';
import resize from '@app/utils/media/resize';
import logger from '@app/utils/logger';
import Tile from './components/Tile';
import FullscreenTile from './components/FullscreenTile';
import OrientationNotice from './components/OrientationNotice';
import { usePostManager } from './hooks';

import 'swiper/swiper-bundle.css';
import './swiper.css';

SwiperCore.use([Autoplay]);

function isPending(update) {
  return update.added > 0 || update.removed > 0;
}

const fromSeconds = (seconds) => seconds * 1000;

const POST_SCHEDULER_INTERVAL = fromSeconds(9);
const SUPERPOST_SCHEDULER_INTERVAL = fromSeconds(55);
const AUTOPLAY_RESTART_TIMEOUT = fromSeconds(5);
const AUTOPLAY_INTERVAL = fromSeconds(3);

const MobileScreen = ({ settings, kiosk, adesa, randomize }) => {
  const swiperRef = useRef(null);
  const restartCountdownRef = useRef(null);
  const pendingUpdateRef = useRef({
    removed: 0,
    added: 0,
    suppressCallbacks: false,
    activeIndexBefore: 0,
    slidesBefore: 0,
  });
  const postManager = usePostManager();
  const [initialWindowWidth] = useState(window.innerWidth);
  const [initialWindowHeight] = useState(window.innerHeight);
  const [slides, setSlides] = useState([]);
  const [takeover, setTakeover] = useState(null);

  const handleTransitionEnd = useCallback(
    (swiper) => {
      if (pendingUpdateRef.current.suppressCallbacks) {
        return;
      }

      setSlides((prevSlides) => {
        const nextSlides = [...prevSlides];

        // Append more if at end
        if (swiper.isEnd) {
          const post = postManager.dequeue();
          if (post) {
            const [width, height] = resize(post.media.width, post.media.height, initialWindowWidth);
            nextSlides.push({
              id: nanoid(),
              post,
              width,
              height,
              visible: true,
            });
            pendingUpdateRef.current.added += 1;
          }
        }

        // Trim
        const offscreenSlides = takeWhile(nextSlides, ['visible', false]);
        const historySize = 5;
        const phasedOut = Math.max(offscreenSlides.length - historySize, 0);
        for (let i = 0; i < phasedOut; i++) {
          const slide = nextSlides.shift();
          postManager.requeue(slide.post);
        }
        pendingUpdateRef.current.removed += phasedOut;
        pendingUpdateRef.current.activeIndexBefore = swiperRef.current.activeIndex;
        pendingUpdateRef.current.slidesBefore = swiperRef.current.slides.length;

        return nextSlides;
      });
    },
    [postManager, initialWindowWidth]
  );

  const handleExpand = useCallback(
    (post, { x, y }) => {
      if (restartCountdownRef.current !== null) {
        clearTimeout(restartCountdownRef.current);
      }

      swiperRef.current.autoplay.stop();
      const slide = find(slides, ['post.id', post.id]);
      setTakeover({ slide, post, x, y, trigger: 'manual' });
    },
    [slides]
  );

  const handleCollapse = useCallback(() => {
    setTakeover(null);
    swiperRef.current.autoplay.start();
  }, []);

  const handleCommand = useCallback(
    (command) => {
      if (command.type === CommandType.posts.unpublish) {
        const unpublishedPost = command.payload;
        const removedPostsCount = postManager.remove(unpublishedPost);

        // Post might be on the screen so remove immediately
        if (removedPostsCount === 0) {
          // TODO implement if necessary, for now though, i'll prioritize user experience (no jumping UI)
        }
      }
    },
    [postManager]
  );

  // Respond to remote commands received from the central hub
  useHub(handleCommand);

  useEffect(() => {
    async function fetchData() {
      // init sequence
      postManager.setRandomize(randomize);
      postManager.setFilters({ adesa });
      postManager.setMaxSize(settings.queueLimit);

      await postManager.syncSuperPosts();
      await postManager.syncRecentPosts();

      setSlides((prevSlides) => {
        const nextSlides = [...prevSlides];
        let surfaceAreaToCover = initialWindowHeight * 2;

        while (true) {
          const post = postManager.dequeue();
          if (!post) {
            break;
          }

          const [width, height] = resize(post.media.width, post.media.height, initialWindowWidth);
          nextSlides.push({
            id: nanoid(),
            post,
            width,
            height,
            visible: true,
          });

          surfaceAreaToCover -= height;
          if (surfaceAreaToCover <= 0) {
            break;
          }
        }

        pendingUpdateRef.current.added += nextSlides.length;
        return nextSlides;
      });
    }

    // Preload queues
    fetchData();
  }, [postManager, initialWindowWidth, initialWindowHeight]);

  useEffect(() => {
    // Keep recent post queue up-to-date
    const recentPostIntervalId = setInterval(() => {
      postManager.syncRecentPosts();
    }, POST_SCHEDULER_INTERVAL);

    return () => clearInterval(recentPostIntervalId);
  }, [postManager]);

  useEffect(() => {
    // Keep superpost queue up-to-date
    const superPostIntervalId = setInterval(() => {
      postManager.syncSuperPosts();
    }, SUPERPOST_SCHEDULER_INTERVAL);
    return () => clearInterval(superPostIntervalId);
  });

  useLayoutEffect(() => {
    if (swiperRef.current && isPending(pendingUpdateRef.current)) {
      // Update method automatically alters the active index if the new total
      // number of slides after update is smaller than the active index, so
      // we need to compensate for that when calling slideTo below.
      const activeIndexBefore = pendingUpdateRef.current.activeIndexBefore;
      const slidesBefore = swiperRef.current.slides.length;
      // Heads Up!
      // In production mode, Safari (and possibly other browsers)
      // run this update immediately after slides are changed
      // and this is in accordance with the Swiper implementation.
      // However, in dev mode, it works differently for whatever
      // reason. One possible solution is to store before values
      // right before calling setSlides as seen above this comment.
      swiperRef.current.update();
      const activeIndexAfter = swiperRef.current.activeIndex;
      const slidesAfter = swiperRef.current.slides.length;

      // Only offset the active index when removing from the front,
      // because adding new slides doesn't alter the active index.
      if (pendingUpdateRef.current.removed > 0) {
        // slideTo triggers transitionEnd synchronously so we gotta suppress the transition callback
        pendingUpdateRef.current.suppressCallbacks = true;

        const compensation = Math.max(0, activeIndexBefore - activeIndexAfter);
        swiperRef.current.slideTo(
          swiperRef.current.activeIndex + compensation - pendingUpdateRef.current.removed,
          0,
          false
        );

        logger.log(
          'Compensating',
          compensation,
          activeIndexBefore,
          activeIndexAfter,
          slidesBefore,
          slidesAfter
        );

        // re-enable transition callbacks
        pendingUpdateRef.current.suppressCallbacks = false;
      }

      pendingUpdateRef.current.added = 0;
      pendingUpdateRef.current.removed = 0;
    }
  });

  const handleTouchStart = useCallback(() => {
    if (restartCountdownRef.current !== null) {
      clearTimeout(restartCountdownRef.current);
    }

    if (swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.stop();
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    const delay = Math.max(AUTOPLAY_RESTART_TIMEOUT - AUTOPLAY_INTERVAL, 0);
    restartCountdownRef.current = setTimeout(() => {
      logger.log(`Autoplay > Restarting in ${delay / 1000}s`);
      swiperRef.current.autoplay.start();
      restartCountdownRef.current = null;
    }, delay);
  }, []);

  const swiperOptions = {
    direction: 'vertical',
    slidesPerView: 'auto',
    spaceBetween: 0,
    snapTo: 'end',
    longSwipesRatio: 0.3,
    longSwipesMs: 300,
    grabCursor: true,
    autoplay: {
      delay: AUTOPLAY_INTERVAL,
      stopOnLastSlide: true,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    onSwiper: (swiper) => (swiperRef.current = swiper),
    onTransitionEnd: handleTransitionEnd,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
  };

  return (
    <Fragment>
      {slides.length > 0 && (
        <Swiper {...swiperOptions}>
          {slides.map((slide) => {
            return (
              <SwiperSlide key={slide.id} style={{ height: slide.height }}>
                {({ isVisible }) => {
                  // We mutate the post here to mark invisible posts
                  slide.visible = isVisible;

                  return (
                    <Tile
                      post={slide.post}
                      width={slide.width}
                      height={slide.height}
                      suspend={!!takeover}
                      onExpand={handleExpand}
                      showLove
                    />
                  );
                }}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}

      <Condition when={takeover}>
        <FullscreenTile
          takeover={takeover}
          collapsedWidth={takeover && takeover.slide.width}
          collapsedHeight={takeover && takeover.slide.height}
          onCollapse={handleCollapse}
          showLove
        />
      </Condition>

      <OrientationNotice suppress={!!takeover} />
    </Fragment>
  );
};

MobileScreen.propTypes = {
  settings: PropTypes.object,
};

export default MobileScreen;
