import ShifterItem from './ShifterItem';
import styles from './index.module.css';

const Shifter = ({ position = 0, delay = 0, transitioning, children, ...rest } = {}) => {
  return (
    <div
      className={styles.Root}
      style={{
        transform: `translate3d(${position}px, 0, 0)`,
        transition: transitioning ? `transform 1s ${delay}s cubic-bezier(.42, 0, .58, 1)` : 'none',
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

Shifter.Item = ShifterItem;

export default Shifter;
