import orderBy from 'lodash/orderBy';
import shuffle from 'lodash/shuffle';
import posts from '@app/api/posts';
import superposts from '@app/api/superposts';
import differenceBy from 'lodash/differenceBy';
import logger from '@app/utils/logger';
import diffInMinutes from '@app/utils/datetime/diffInMinutes';
import isSameOrAfter from '@app/utils/datetime/isSameOrAfter';
import isSame from '@app/utils/datetime/isSame';
import addMinutes from '@app/utils/datetime/addMinutes';

class PostManager {
  // Buffer of superposts that are currently active (within their specified validity interval)
  activeSuperPosts = [];
  // This sequence number helps us correctly identify pending superposts.
  lastIterationSeqNumberByPostId = new Map();
  // This provides data points for the "Mark & sweep" style garbage collector
  // We mark stale assets that are on the screen as garbage by adding them to the Set
  // and "sweep" them all when they're being phased out off the screen.
  markedPostIds = new Set();
  // This is essentially a buffer full of posts that are readily available
  // to be pushed on to the screen.
  postQueue = [];
  // Posts that were dequeued and put on display, for book keeping purposes
  onScreenPosts = [];
  // Max queue size
  maxSize = 50;

  constructor(options = { maxSize: 50 }) {
    this.maxSize = options.maxSize;
    window.postManager = this;
  }

  setMaxSize(maxSize) {
    this.maxSize = maxSize;
  }

  setFilters(filters) {
    this.filters = filters;
  }

  setRandomize(randomize) {
    this.randomizeOnInit = randomize;
  }

  reconcile(onScreenPosts, pendingSuperPosts, moreRecentPosts, lessRecentPosts, maxSize) {
    const result = [];

    const leastRecentPostsCopy = lessRecentPosts.slice();
    while (leastRecentPostsCopy[0] && leastRecentPostsCopy[0].isSuperPost) {
      result.push(leastRecentPostsCopy.shift());
    }

    // enqueue all pending superposts, no limits apply here,
    // it may cause the queue to grow out of bounds briefly, that's okay
    pendingSuperPosts.forEach((superpost) => {
      // TODO:
      // we should never enqueue a super post if it's on screen already,
      // this will likely never happen, but leaving a note anyway
      result.push(superpost);
    });

    // settings dictate a certain queue length limit but remember, some of the posts are in a different array
    const onScreenNonSuperPosts = onScreenPosts.filter((post) => !post.isSuperPost);
    const queueCapacity = maxSize - onScreenNonSuperPosts.length + pendingSuperPosts.length;

    // enqueue most recent posts (they essentialy cut the queue)
    while (result.length < queueCapacity && moreRecentPosts.length > 0) {
      result.push(moreRecentPosts.shift());
    }

    // concat displayedPosts and nonRecentPosts and order them by publishedAt descending,
    const lessRecentNonSuperPosts = lessRecentPosts.filter((post) => !post.isSuperPost);
    const allLeastRecentNonSuperPosts = orderBy(
      onScreenNonSuperPosts.concat(lessRecentNonSuperPosts),
      ['publishedAt'],
      ['desc']
    );

    // now split the array in two — posts that we'll keep and those we'll discard,
    let postsToKeepCount = Math.max(queueCapacity - result.length, 0);
    const onScreenPostIds = new Set(onScreenNonSuperPosts.map((post) => post.id));
    const toKeep = new Set([]);

    const markedPostIds = [];
    allLeastRecentNonSuperPosts.forEach((post) => {
      if (postsToKeepCount > 0 && !onScreenPostIds.has(post.id)) {
        // out of those we'll discard, we'll want to mark as garbage those that are on display
        // we do so through a Set (1) to preserve the original order
        toKeep.add(post.id);
        postsToKeepCount -= 1;
      } else if (postsToKeepCount === 0 && onScreenPostIds.has(post.id)) {
        markedPostIds.push(post.id);
      }
    });

    // 1
    lessRecentNonSuperPosts.forEach((post) => {
      if (toKeep.has(post.id)) {
        result.push(post);
      }
    });

    return { keep: result, remove: markedPostIds };
  }

  async syncRecentPosts() {
    if (this.fetching) {
      return;
    }

    // entering critical section
    this.fetching = true;

    try {
      // fetch a batch of X most recent posts created after the most recent item we have
      const response = await posts.listAll({
        endingBefore: this.moreRecentEndingBefore,
        limit: this.maxSize,
        adesa: this.filters?.adesa ?? false,
      });

      // Heads Up! recent posts may contain duplicates if these got unpublished and re-published
      let moreRecentPosts = response.data;
      const lessRecentPosts = this.postQueue;
      const pendingSuperPosts = this.getPendingSuperPosts();

      if (moreRecentPosts.length > 0) {
        this.moreRecentEndingBefore = response.previousCursor.value;
      }

      // TODO clarify w/ the team if we want to make another request in case there're even more than 50 most recent items

      // Randomize the starting point for the initial sync after the cursor has been persisted when enabled
      const isInitialSync = lessRecentPosts.length === 0;
      if (this.randomizeOnInit && isInitialSync) {
        moreRecentPosts = shuffle(moreRecentPosts);
      }

      // already queued superposts get the highest priority therefore nothing else gets ahead of them
      const { keep, remove } = this.reconcile(
        this.onScreenPosts,
        pendingSuperPosts,
        moreRecentPosts,
        lessRecentPosts,
        this.maxSize
      );

      remove.forEach((postId) => {
        this.markedPostIds.add(postId);
      });

      this.postQueue = keep;
    } catch (error) {
      logger.log('errored out while syncing posts', error);
    }

    // leaving critical section
    this.fetching = false;
  }

  /**
   * This method queries the active superposts endpoint, deletes the expired superposts
   * and updates internal state when it detects any of the schedules had changed.
   */
  async syncSuperPosts() {
    try {
      const response = await superposts.listActive();
      const allActiveSuperPosts = response.data;

      // remove expired superposts posts along w/ their metadata
      const expiredSuperPosts = differenceBy(
        this.activeSuperPosts,
        allActiveSuperPosts,
        (superpost) => superpost.id
      );
      expiredSuperPosts.forEach((superpost) => {
        this.lastIterationSeqNumberByPostId.delete(superpost.id);
      });

      // check if we're changing superpost properties that should trigger internal state change
      allActiveSuperPosts.forEach((superpost) => {
        const isCached = this.lastIterationSeqNumberByPostId.has(superpost.id);
        const cachedCopy = isCached
          ? this.activeSuperPosts.find((sp) => sp.id === superpost.id)
          : null;

        // Changing frequency or moving start time must reset the sequence number
        if (
          isCached &&
          (cachedCopy.schedule.frequency !== superpost.schedule.frequency ||
            !isSame(cachedCopy.schedule.startAt, superpost.schedule.startAt))
        ) {
          this.lastIterationSeqNumberByPostId.delete(superpost.id);
        }
      });

      this.activeSuperPosts = allActiveSuperPosts;

      // collect expired superposts
    } catch (error) {
      logger.log('error syncing superposts', error);
    }
  }

  /**
   * This method goes through the list of active superposts and returns those that
   * are pending at the time of the execution. A post will only be marked pending
   * under the following circumstances:
   *
   * 1) The current time is greater than the post schedule start and lower than the post schedule end
   * 2) The post has not been marked pending in the current iteration
   * 3) The difference between the current time and the current iteration start is less than 1 minute
   */
  getPendingSuperPosts() {
    const pendingSuperPosts = [];

    this.activeSuperPosts.forEach((superpost) => {
      const now = new Date();

      // beware, diffInMinutes returns 0 for both adjacent minutes
      // e.g. say it's 12:45, it would return 0 for 12:44:xy and 12:46:xy resulting in
      // the post being scheduled 1 minute earlier, that's why we compute not only the sequence number
      // but also the time of the next iteration
      const minutesSinceScheduleStart = diffInMinutes(now, superpost.schedule.startAt);

      if (minutesSinceScheduleStart < 0) {
        return;
      }

      const nextIterationSeqNumber = Math.trunc(
        minutesSinceScheduleStart / superpost.schedule.frequency
      );
      const nextIterationTime = addMinutes(
        superpost.schedule.startAt,
        nextIterationSeqNumber * superpost.schedule.frequency
      );

      const minutesSinceLastIteration = minutesSinceScheduleStart % superpost.schedule.frequency;
      const lastIterationSeqNumber = this.lastIterationSeqNumberByPostId.get(superpost.id);
      const isFirstIteration = typeof lastIterationSeqNumber === 'undefined';

      if (
        minutesSinceLastIteration === 0 &&
        isSameOrAfter(now, nextIterationTime) &&
        (isFirstIteration || lastIterationSeqNumber < nextIterationSeqNumber)
      ) {
        this.lastIterationSeqNumberByPostId.set(superpost.id, nextIterationSeqNumber);
        pendingSuperPosts.push(superpost);
      }
    });

    return pendingSuperPosts;
  }

  getSize() {
    return this.postQueue.length;
  }

  dequeue() {
    const post = this.postQueue.shift();
    if (post) {
      this.onScreenPosts.push(post);
    }
    return post;
  }

  requeue(post) {
    const totalPosts =
      this.postQueue.filter((post) => !post.isSuperPost).length +
      this.onScreenPosts.filter((post) => !post.isSuperPost).length;

    const markedAsGarbage = this.markedPostIds.has(post.id);
    if (totalPosts < this.maxSize && !markedAsGarbage) {
      this.postQueue.push(post);
    }
    if (markedAsGarbage) {
      this.markedPostIds.delete(post.id);
    }

    this.onScreenPosts = this.onScreenPosts.filter((p) => p.id !== post.id);
  }

  remove(post) {
    const prevPostQueueSize = this.postQueue.length;
    this.postQueue = this.postQueue.filter((p) => p.id !== post.id);
    this.onScreenPosts = this.onScreenPosts.filter((p) => p.id !== post.id);
    return prevPostQueueSize - this.postQueue.length;
  }
}

export default PostManager;
