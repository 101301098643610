import httpClient from './client/http';
import getLocalTimezone from '@app/utils/datetime/getLocalTimezone';
import { getAccessToken } from './token';
import { createSuperPostFromJson } from './mappers';

export async function listActive() {
  const headers = { 'Content-Type': 'application/json' };

  const accessToken = getAccessToken();
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const response = await httpClient.get(
    `${
      import.meta.env.VITE_REST_API_URL
    }/v1/screen/superposts/active?timezone=${getLocalTimezone()}`,
    {
      headers,
    }
  );

  return {
    data: response.results.map(createSuperPostFromJson),
  };
}

export default {
  listActive,
};
