import React from 'react';
import classnames from 'classnames';
import LoginButton from '@app/components/LoginButton/LoginButton';
import Notifier from '@app/components/Notifier';
import Condition from '@app/components/Condition';
import styles from './LogoContainer.module.css';

const LogoContainer = ({ className, kiosk, mobile }) => {
  return (
    <div className={classnames(styles.LogoContainer, mobile && styles.Mobile)}>
      {mobile && <div className={styles.LogoContainerGradient} />}
      <div className={styles.Logo}>
        <img src="/Logo.svg" alt="Carvana logo" />
      </div>
      <Condition when={!kiosk}>
        <LoginButton className={styles.LoginButton} />
        <Notifier className={styles.NotificationButton} />
      </Condition>
    </div>
  );
};

export default LogoContainer;
