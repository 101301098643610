import { useRef, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import cx from 'classnames';
import Condition from '@app/components/Condition';
import getOptimizedUrl from '@app/utils/media/getOptimizedUrl';
import LocationCode from './LocationCode';
import LazyVideo from './LazyVideo';
import LazyImage from './LazyImage';
import LoveButton from '@app/components/LoveButton/LoveButton';
import posts from '@app/api/posts';
import ExpandIcon from './Expand.svg?react';
import CameraIcon from './Camera.svg?react';
import PlayIcon from './Play.svg?react';
import styles from './index.module.css';

const Tile = ({ post, width, height, suspend, onExpand, showLove }) => {
  const { authState } = useOktaAuth();
  const ref = useRef(null);

  const handleExpandClick = useCallback(
    (e) => {
      if (post.takeoverMode !== 'disabled' && ref.current) {
        onExpand(post, ref.current.getBoundingClientRect());
      }
    },
    [ref, onExpand, post]
  );

  const handleLikeToggle = useCallback(
    async (active) => {
      if (active) {
        const response = await posts.like(post);
        post.userReaction = response.data.id;
      } else {
        await posts.unlike(post);
        post.userReaction = null;
      }
    },
    [post]
  );

  return (
    <div
      ref={ref}
      className={styles.Tile}
      style={{ width, height }}
      onClick={handleExpandClick}
      data-ga="post-tile"
    >
      <Condition when={authState && authState.isAuthenticated && showLove}>
        <LoveButton
          defaultActive={!!post.userReaction}
          onToggle={handleLikeToggle}
          tileWidth={width}
          tileHeight={height}
        />
      </Condition>

      <Condition when={post.media.resourceType === 'image'}>
        <LazyImage
          src={getOptimizedUrl({
            publicId: post.media.publicId,
            cloudName: post.media.keyName,
            resourceType: post.media.resourceType,
            height: 510, // TODO: change this
            crop: post.media.extension === 'gif' ? null : 'scale',
          })}
        />
      </Condition>

      {/* TODO: Review autoplay policy https://developers.google.com/web/updates/2017/09/autoplay-policy-changes */}
      <Condition when={post.media.resourceType === 'video'}>
        <LazyVideo
          playing={!suspend}
          src={getOptimizedUrl({
            publicId: post.media.publicId,
            crop: 'scale',
            format: 'mp4',
            cloudName: post.media.keyName,
            resourceType: post.media.resourceType,
            height: 510, // TODO: change this
          })}
          type="video/mp4"
          poster={post.media.thumbnailUrl}
        />
      </Condition>

      <Condition when={post.takeoverMode !== 'disabled'}>
        <div className={styles.TileHeaderWrapper}>
          <button
            type="button"
            className={styles.TileDetailModeToggleBtn}
            onClick={handleExpandClick}
            data-ga="expand-post-button"
          >
            <ExpandIcon />
          </button>
          <Condition when={post.media.resourceType === 'video'}>
            <CameraIcon className={styles.TileHeaderVideoIndicator} />
          </Condition>
        </div>
      </Condition>

      <Condition when={post.media.resourceType === 'video'}>
        <PlayIcon className={styles.TileVideoPlayIcon} />
      </Condition>

      <div className={styles.TileContentOuterWrapper}>
        <div className={styles.TileContent}>
          <Condition when={post.location.code}>
            <LocationCode className={styles.TileContent__Location} code={post.location.code} />
          </Condition>

          <div className={styles.TileContentInnerWrapper}>
            <Condition when={post.caption}>
              <div className={cx(styles.TileContent__Caption, styles.ClampTwoLines)}>
                {post.caption}
              </div>
            </Condition>

            <Condition when={post.author.firstName && post.author.lastName}>
              <div className={cx(styles.TileContent__Author, styles.ClampOneLine)}>
                {post.author.firstName} {post.author.lastName}
              </div>
            </Condition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
